import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { writedb } from "../firebase-config";
import { useParams } from "react-router-dom";

const QrCodePage = () => {
  const [imageList, setImageList] = useState([]);
  const key = useParams().key;
  const [notFound, setNotFound] = useState(true);
  const [loading, setLoading] = useState(true); // State to manage loading indicator

  useEffect(() => {
    const fetchImages = async () => {
      setLoading(true); // Show loading indicator
      try {
        const snapshot = await writedb.ref("qrCodes/" + key).once("value");
        console.info("qrCodes/" + key);
        if (snapshot.exists()) {
          console.info("hello");
          setNotFound(false);
          const imageData = snapshot.val().imageList;
          if (Array.isArray(imageData)) {
            setImageList(imageData);
          } else {
            setNotFound(true);
          }
        } else {
          setNotFound(true);
        }
      } catch (error) {
        console.error("Error fetching images:", error);
      } finally {
        setLoading(false); // Hide loading indicator
      }
    };

    fetchImages();
  }, [key]);

  return (
    <Box
      maxWidth="100vw"
      className="hide-overflow-600px"
      display="flex"
      justifyContent={notFound ? "center" : "start"}
      alignItems={notFound ? "center" : "start"}
      minHeight="100vh"
      bgcolor="#364052" // Remove page background
      flexDirection="column" // Align images in a column
    >
      {loading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100vw"
          flexDirection="column"
          height="100vh"
          position="absolute"
          top="0"
          left="0"
          bgcolor="#364052"
          zIndex="9999"
        >
          <CircularProgress color="secondary" />
          <Typography variant="h5" align="center" gutterBottom color="white" mt="15px">
            Please Wait...
          </Typography>
        </Box>
      )}

      {!notFound || loading ? (
        ""
      ) : (
        <Typography variant="h5" align="center" gutterBottom color="white">
          No Data Found
        </Typography>
      )}

      {notFound
        ? ""
        : imageList.map((imageUrl, index) => (
            <Box
              key={index}
              display="flex"
              width="100vw"
              height="auto"
              justifyContent="center"
              alignItems="center"
              maxWidth="100vw"
            >
              <img
                src={imageUrl}
                alt={`Image ${index + 1}`}
                style={{ maxWidth: "100%", height: "auto", display: "block" }}
              />
            </Box>
          ))}
    </Box>
  );
};

export default QrCodePage;
