import { Routes, Route } from "react-router-dom";
import DefaultPage from "./scenes";
import QrCodePage from "./scenes/qrcode";
import { Box } from "@mui/material";

function App() {
  return (
    <Box className="app">
      <main className="content">
        <Routes>
          <Route exact path="/" element={<DefaultPage />} />
          <Route path="/:key" element={<QrCodePage />} />
        </Routes>
      </main>
    </Box>
  );
}

export default App;
