import React from "react";
import { Box, Typography } from "@mui/material";

const DefaultPage = () => {
  return (
    <Box
      maxWidth="100vw"
      className="hide-overflow-600px"
      paddingRight="5px"
      display="flex"
      bgcolor="#364052"
      flexDirection="column"
      justifyContent="center" // Center content horizontally
      alignItems="center" // Center content vertically
      minHeight="100vh" // Ensure Box takes full viewport height
    >
      <Typography fontSize={23} align="center" color="white">
        Welcome to Brandbite
      </Typography>
      <Typography  fontSize={27} align="center" color="white" mt="10px">
        The best menumaker you ever seen
      </Typography>
    </Box>
  );
};

export default DefaultPage;
